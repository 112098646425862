<template>
  <div class="card" no-body>
    <b-card-header header-tag="header" class="p-0" role="tab">
      <b-button
        block
        v-b-toggle="`collapsed-item-${index}`"
        variant="primary"
        class="btn-header-link"
        >{{ item.title }}</b-button
      >
    </b-card-header>

    <b-collapse :id="`collapsed-item-${index}`" v-model="isVisible">
      <b-card v-if="item.type === 'map'">
        <Map :width="'100%'" :height="'250'" :address="item.address" />
      </b-card>
      <b-card v-else-if="item.type === 'fac'">
        {{ item.message }}
        <p><Facilitie v-for="(elm, index) in item.fac" :key="index" :data="elm.name" /></p>
      </b-card>
      <b-card v-else-if="item.type === 'html'">
        <ul v-html="item.message"></ul>
      </b-card>
      <div v-else-if="item.type === 'image'">
        <ImageView :item="item" v-for="(item, inx) in item.items" :key="inx"/>
      </div>
      <b-card v-else class="">
        {{ item.message }}
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BButton, BCollapse, VBToggle, BCard, BCardHeader } from 'bootstrap-vue';

export default {
  components: {
    BButton,
    BCollapse,
    BCard,
    BCardHeader,
    Facilitie: () => import('@/components/productPage/atoms/Facilitie'),
    Map: () => import('@/components/productPage/atoms/GoogleMap'),
    ImageView: () => import('@/components/atoms/ImageView'),
  },
  directives: {
    'b-toggle': VBToggle,
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
    }),
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isVisible: false,
    };
  },
};
</script>

<style scoped>
header button.btn {
  text-align: inherit;
}
.card .card-header .btn-header-link.collapsed:after {
  content: "\f107";
}
.card .card-header .btn-header-link:after {
  content: "\f106";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  float: left;
}
.left-align .card .card-header .btn-header-link:after {
  float: right;
}
.card {
  margin-bottom: 5px;
}
.card .card-body ul {
  padding-right: 0px;
}
</style>
